

























































import {Component, Vue} from "vue-property-decorator";
import {mapActions, mapGetters, mapState} from "vuex";

import PageLayout from "@/components/layouts/PageLayout.vue";
import Footer from "@/components/Footer.vue";
import Form from "@/components/UI/form/Form.vue";
import Input from "@/components/UI/form/Input.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import Button from "@/components/UI/Button.vue";

@Component({
  computed: {
    ...mapState("changePassword", [
      "isFormValid",
      "errorMessage",
      "isSubmitActive",
    ]),
    ...mapGetters("changePassword", ["getFormControls"])
  },
  methods: {
    ...mapActions("changePassword", [
      "setPassword",
      "inputHandler",
    ])
  },
  components: {
    Form,
    Input,
    Button,
    Checkbox,
    PageLayout,
    Footer
  }
})
export default class PasswordChange extends Vue {

}
